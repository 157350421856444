import React from 'react';
import { graphql } from 'gatsby';
import PageContent from './components/Page';

import Layout, { Content } from './components/Layout';
import HeroHeader from './components/heroheader';
import ContentNavigation from './components/Navigation/Content';
import {
  Container as BlogContainer,
  Entry as BlogEntry,
} from './components/Blog';
import createPersistedState from 'use-persisted-state';
import { Currencies } from './components/config/currency';

const useCurrency = createPersistedState('currency');

const CURRENCY_SYMBOLS = {};
Currencies.supported.forEach(({ code, symbol }) => {
  CURRENCY_SYMBOLS[code] = symbol;
});

export const query = graphql`
  query ProgrammeBlogQuery($id: Int!) {
    strapiProgrammes(strapiId: { eq: $id }) {
      strapiId
      Duration
      Title
      Theme
      Pipedrive_ID
      Category {
        id
      }
      Cost {
        EUR
        GBP
        USD
        NZD
        AUD
      }
      Deposit {
        EUR
        GBP
        USD
        NZD
        AUD
      }
      CTA {
        Text
        URL
        Button
      }
      Key_Visual {
        publicURL
      }
      Eligibility {
        Title
      }
    }
  }
`;

const ProgrammeBlog = ({ data, pageContext, location }) => {
  const [activeCurrency] = useCurrency(Currencies.default);

  const programme = data.strapiProgrammes;
  const programmeContext = pageContext;
  const navigation = pageContext.navigation;

  const { blogs, page, homepage } = pageContext;
  const title = [programme.Title];
  let keyVisualUrl = programme.Key_Visual
    ? programme.Key_Visual.publicURL
    : '/images/destinations.jpg';
  let cost = programme.Cost;
  const theme = programme.Theme || 'default';

  if (page) {
    if (!homepage) {
      title.push(page.Headline || page.Display_Title);
    }
    keyVisualUrl = page.Key_Visual ? page.Key_Visual.publicURL : keyVisualUrl;
    cost = page.Cost || cost;
  }

  if (blogs) {
    title.push('Blogs');
  }
  const metadata = programme.Metadata || { Title: false };

  return (
    <Layout
      title={metadata.Title || title.join(' ')}
      desc={metadata.Description || ''}
      noIndex={metadata.noIndex}
    >
      <HeroHeader
        theme={theme}
        title={title.join(' ')}
        cta={programme.CTA}
        backgroundUrl={keyVisualUrl}
        infoData={{
          pipedriveId: programme.Pipedrive_ID,
          cost: `${CURRENCY_SYMBOLS[activeCurrency]} ${
            cost && cost[activeCurrency] ? cost[activeCurrency] : '-'
          }`,
          deposit: `${CURRENCY_SYMBOLS[activeCurrency]} ${
            programme.Deposit ? programme.Deposit[activeCurrency] : '-'
          }`,
          duration: programme.Duration,
          eligibility: programme.Eligibility.map(elem => elem.Title).join(', '),
          navigation,
          parentSlug: programmeContext.slug,
        }}
      />
      <ContentNavigation
        theme={theme}
        links={navigation}
        currentPathName={location.pathname}
      />
      <Content>
        {page &&
          page.Content &&
          page.Content.map((content, index) => (
            <PageContent theme={theme} key={index} content={content} />
          ))}
        {blogs && (
          <BlogContainer>
            {blogs.map(blog => (
              <BlogEntry key={blog.strapiId} blog={blog} />
            ))}
          </BlogContainer>
        )}
      </Content>
    </Layout>
  );
};

export default ProgrammeBlog;
